import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
  } from "react-router-dom";
  
  import SignIn from '../pages/SignIn';
  import NotFound from '../pages/NotFound'
  import Dashboard from '../pages/Dashboard';
  import Relatorio from '../pages/Relatorio';
  
  export const Router = ()=>{
 

    const PrivateRoute = ({ children, redirectTo }) => {
      const isAuthenticated = sessionStorage.getItem("userToken") !== null;
      //console.log("isAuth: ", localStorage.getItem("userToken"));
      return isAuthenticated ? children : <Navigate to={redirectTo} />;
    };
    
    return(
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<SignIn />}/>
          <Route path="/:ambiente" element={<SignIn />}/>
	        <Route path="*" element={<NotFound />}/>
          <Route path="/dashboard" element={<PrivateRoute redirectTo="/"><Dashboard /></PrivateRoute>}/>
          <Route path="/report" element={<PrivateRoute redirectTo="/"><Relatorio /></PrivateRoute>}/>
      </Routes>
    </BrowserRouter>
    )
  }


export default Router
  