import { HeaderContainer, HeaderWrapper, UserInfo, Containertab, DashboardBackground, ModalAlinham, BodyContainer, Divbut, InlineContainer2, InlineContainer, Bot, HistoryChamados, DivTitulo, LoginInput, ButtonContainer } from './styles';
import React, { useState, useEffect } from 'react';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import api from "../../Services/api";
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import nowT from '../../assets/images/nowT.bmp';
import { IoIosNotifications } from "react-icons/io";
import Select from 'react-select'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import HistoryFinanceCard from '../../components/HistoryFinanceCard';

interface Locais {
    idLocal: string;
    descricao: string;
    pavimento: string;
    ativo: number;
    ambiente: string;
    grupodelocais: string;
    equiperespons: string;
    ID: number;
}

const Dashboard = (props: any) => {

    const Obj = JSON.parse(sessionStorage.getItem("dados") || '{}');
    const [toggleState, setToggleState] = useState(1);
    const [empresa, setEmpresa] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tombamento, setTombamento] = useState('');
    const [local, setLocal] = useState('');
    const [observacao, setObservacao] = useState('');
    const [tipoSolicitacao, setTipoSolicitacao] = useState('');
    const [subtipo, setSubtipo] = useState('');
    const [email, setEmail] = useState('');
    const location = useLocation();
    const [mensagem, setMensagem] = useState('');
    //  const [ambientes, setAmb] = useState(teste);
    const [task, setTask] = useState([]);
    const navigate = useNavigate();
    const [filtro, setFiltro] = useState(99);
    const [ativarButton, setAtivarButton] = useState(undefined);
    const [dataIni, setDataini] = useState('');
    const [datafim, setDatafim] = useState('');
    const [notif, setNotif] = useState('9');
    const [renderNotif, setRenderNotif] = useState();
    const [quantNot, setQuantNot] = useState('0');
    const [userApp, setUserApp] = useState('');

    const [optionsEmpresa, setOptionsEmpresa] = useState([])
    const [optionsLocais, setOptionsLocais] = useState([])
    const [optionsLocaisFilter, setOptionsLocaisFilter] = useState([])

    const [optionsTipoSolicitacao, setOptionsTipoSolicitacao] = useState([])
    const [optionsSubTipo, setOptionsSubTipo] = useState([])
    const [optionsSubTipoFilter, setOptionsSubTipoFilter] = useState([])

    const [data, setData] = useState([]);
    const [tasks, setTasks] = useState([])
    const [selection, setSelection] = useState({ 20: "", 30: "", 40: "", 50: "", 99: "" });
    const [optionsLocal, setOptionsLocal] = useState([])
    const [optionsLoc, setOptionsLoc] = useState([])

    api.defaults.headers.common['Authorization'] = sessionStorage.getItem("userToken") || '';

    useEffect(() => {


        setEmpresa(sessionStorage.getItem("amb") || '')

        //setResponsavel(sessionStorage.getItem("username") || '')

        setTelefone(sessionStorage.getItem("phone") || '')

        GetLocal();
        getTask();
    }, []);


    async function getTask() {

        let chamados: any;

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })

        setTask(<Loading /> as any);

        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios.get(endpoint + '/task/taskuserid', {
            params: {
                id: sessionStorage.getItem("id")
            }
        })
            .then(async function (response) {

                if (response.status === 200) {

                    if(response.data.data.length > 0){
                    chamados = await reactObj(response.data.data);

                console.log(response.data.data)
                
                    setTasks(response.data.data);
                    setTask(chamados);
                    // setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);
                    }else{
                        setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
                    }
                }else{
                    setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
                }
            })
            .catch(async function (error) {

                setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)

            });

    }

    function reactObj(objeto: any) {

        const chamados = objeto.map((prod: any, index: any) => {
            return <HistoryFinanceCard
                key={index}
                obj={prod}
                updateTask={getTask}
            />
        })
        return chamados;

    }


    async function GetLocal() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/locais/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {

            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description + " (" + user.loc_alternativeidentifier + ")" });
                });

                setOptionsLoc(arr)
                setOptionsLocal(valor)

            }

        })
            .catch(function (error) {
                setOptionsLocal([])
            });
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '35px',
            textAlign: 'left',
            borderColor: state.isFocused ? 'grey' : '#92989e',
            fontWeight: 'bold',
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            padding: '0 6px',

        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',

        }),
    };


    function salvarTar() {

        if (responsavel === "") {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Digite o solicitante.`,
                confirmButtonColor: "#222d32",
            })
        } else if (local === "") {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Selecione o local`,
                confirmButtonColor: "#222d32",
            })
        } else if (observacao === "") {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Digite o problema encontrado.`,
                confirmButtonColor: "#222d32",
            })
        } else {

            let today = new Date();
            let date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                today.getDate().toString().padStart(2, "0");
            let time =
                today.getHours().toString().padStart(2, "0") +
                ":" +
                today.getMinutes().toString().padStart(2, "0");
            let JuntarDate = date + "T" + time;

            const dados = [{
                tty_id: 51,
                tsk_active: 1,
                tsk_recreate: 0,
                tsk_notification: 0,
                tea_id: 38,
                tsf_id: 1,
                loc_id: local,
                tsk_integrationid: '',
                stn_id: 40,
                src_id: 5,
                tsk_observation: observacao,
                tsk_responsible: responsavel,
                tsk_requester: responsavel,
                tsk_telephone: telefone,
                tsk_scheduleinitialdatehour: JuntarDate,
                age_id_insert: sessionStorage.getItem("id")
            }]

            const _user = secureLocalStorage.getItem('t') as ''
            const endpoint = secureLocalStorage.getItem('ep') as ''


            axios({
                method: "post",
                url: endpoint + '/task/insert',
                headers: {
                    Authorization: _user
                },
                data: {
                    data: dados,
                }
            }).then(async function (ret) {
                if (ret.data.status === 200) {


                    Swal.fire({
                        icon: 'success',
                        text: `Chamado aberto com sucesso.`,
                        confirmButtonColor: "#222d32",
                    })

                    setLocal("")
                    setObservacao("")
                    getTask()
                }

            })
                .catch(function (error) {

                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: `Não foi possível concluir o chamado.`,
                        confirmButtonColor: "#222d32",
                    })

                });

        }


    }

    function filtrar(id: number) {

        setFiltro(id);

        const newSelection = { 20: "", 30: "", 40: "", 50: "", 99: "" }
        setSelection(selection => (
            {
                ...newSelection,
                [id]: "2px dotted #000"
            }
        ))

        let tsk: any

        if (tasks !== null) {

            if (id !== 99) {

                const newTask = tasks.filter((value: any) => {
                    return value.stn_id == id ? true : false;

                })


                if (newTask.length > 0) {
                    tsk = reactObj(newTask)
                    setTask(tsk);
                } else {
                    setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
                }


            } else {
                tsk = reactObj(tasks)
                setTask(tsk);
            }
        }
    }


    async function pesquisa() {
        let ret: AxiosResponse;
        let chamados: any;
        let FiltrarAguardando: any
        let tsk: []

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })

        if (dataIni === '' && datafim === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Selecione a data inicial e data final para realizar a busca.`,
                confirmButtonColor: "#222d32",
            })
        } else {

            if (datafim < dataIni) {

                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Selecione a data inicial e data final para realizar a busca.`,
                    confirmButtonColor: "#222d32",
                })
                setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
            } else {

                FiltrarAguardando = tasks.filter(
                    (e: any) => {
                        return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIni &&
                            e.tsk_scheduleinitialdatehour.substring(0, 10) <= datafim
                    }
                );

                if (FiltrarAguardando.length > 0) {
                    tsk = reactObj(FiltrarAguardando)
                    setTask(tsk);
                } else {
                    setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
                }

            }
        }
    }




    const handleLogoff = () => {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("message");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("dados");

        let teste = sessionStorage.getItem("amb") || ''

        navigate('/' + teste);
    }

    return (

        <DashboardBackground>

            <HeaderContainer>
                <HeaderWrapper>
                    <img src={nowT} width={172} height={61} alt="now" />
                    <UserInfo>
                        <div className='username'>
                            <p>Olá, <span className="primary-color font-bold">{sessionStorage.getItem("username")}</span></p>
                            <a href="#" onClick={handleLogoff}>Sair</a>
                        </div>
                    </UserInfo>
                </HeaderWrapper>
            </HeaderContainer>

            <BodyContainer>

                <Card width="90%">

                    <h2 className="h2">ABRIR CHAMADO</h2>


                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-user icon"></i>
                            <input disabled={true} className="input-field-s" type="text" id="RESPONSAVEL" placeholder="Ambiente" value={empresa} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-user icon"></i>
                            <input className="input-field-s" type="text" id="SOLICITANTE" placeholder="Solicitante" onChange={(e) => setResponsavel(e.target.value)} value={responsavel} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-phone icon"></i>
                            <input className="input-field-s" type="text" placeholder="Telefone" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-qrcode icon"></i>
                            <div className="select-box-ativos-select">
                                <Select
                                    maxMenuHeight={150}
                                    placeholder={'LOCAL / EQUIPAMENTO'}
                                    options={optionsLoc}
                                    styles={customStyles}
                                    value={optionsLoc.filter(function (option: any) {
                                        return option.value === local;
                                    })}
                                    onChange={(options: any) =>
                                        !options ? setLocal('') : setLocal(options.value)
                                    }
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ebebeb',
                                            primary: '#52b788',
                                        },
                                    })}
                                />
                            </div>
                        </LoginInput>
                    </InlineContainer>


                    <InlineContainer>
                        <LoginInput>
                            <textarea className="input-field-s" maxLength={2000} placeholder="Observação" onChange={(e) => setObservacao(e.target.value)} value={observacao} />
                        </LoginInput>
                    </InlineContainer>
                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                    <ButtonContainer>
                        <Button type="button" onClick={salvarTar} id="salvar" >ENVIAR</Button>
                    </ButtonContainer>
                </Card>
            </BodyContainer>

            <BodyContainer>
                <Card width="90%">
                    <DivTitulo>
                        <a href="#" className="notification">
                            <IoIosNotifications className='ico-Not' />
                            {quantNot != '0' ?
                                <span className="badge">{quantNot}</span> : null}
                        </a>
                        <h2 className="h3">ÚLTIMOS CHAMADOS</h2>
                    </DivTitulo>

                    <Bot>

                        <button className="button button5" style={{ border: selection[20] }} onClick={() => { filtrar(20) }}>Em analise</button>
                        <button className="button button4" style={{ border: selection[30] }} onClick={() => { filtrar(30) }}>Aguardando</button>
                        <button className="button button3" style={{ border: selection[40] }} onClick={() => { filtrar(40) }}>Em campo</button>
                        <button className="button button2" style={{ border: selection[50] }} onClick={() => { filtrar(50) }}>Encerrada</button>
                        <button className="button button6" style={{ border: selection[99] }} onClick={() => { filtrar(99) }}>Todos</button>
                    </Bot>
                    <Divbut>
                        <div className="container-1">
                            <div id="content1">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idini"

                                    onChange={(e) => setDataini(e.target.value)}
                                    value={dataIni}
                                />

                            </div>
                            <div id="content2">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idfim"
                                    onChange={(e) => setDatafim(e.target.value)}
                                    value={datafim}
                                />

                            </div>
                            <div id="content3">
                                <h1 className="h1-busca" onClick={pesquisa}>Pesquisar</h1>
                            </div>

                        </div>
                    </Divbut>
                    <HistoryChamados>
                        {task}
                    </HistoryChamados>
                </Card>
            </BodyContainer>

      

        </DashboardBackground>

    )
}

export default Dashboard