import styled from 'styled-components';


export const InputContainer = styled.div`
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #52b788;
    border-radius: 10px;
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    z-index: 5000;

    
   .input-amb{
    text-transform: uppercase;
   }

    input {
        font-size: 16px;
        font-weight: 450;
        background: transparent;
        border: 0;
        width: 100%;
        margin: 0 20px
        
    }
`