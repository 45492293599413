import { HeaderContainer, HeaderWrapper, UserInfo, Containertab, DashboardBackground, ModalAlinham, BodyContainer, Divbut, InlineContainer2, InlineContainer, Bot, HistoryChamados, DivTitulo, LoginInput, ButtonContainer } from './styles';
import React, { useState, useEffect } from 'react';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import api from "../../Services/api";
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import nowT from '../../assets/images/nowT.bmp';
import { IoIosNotifications } from "react-icons/io";
import Select from 'react-select'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import HistoryFinanceCard from '../../components/HistoryFinanceCard';

interface Locais {
    idLocal: string;
    descricao: string;
    pavimento: string;
    ativo: number;
    ambiente: string;
    grupodelocais: string;
    equiperespons: string;
    ID: number;
}

const Relatorio = (props: any) => {

    const Obj = JSON.parse(sessionStorage.getItem("dados") || '{}');
    const [toggleState, setToggleState] = useState(1);
    const [empresa, setEmpresa] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tombamento, setTombamento] = useState('');
    const [local, setLocal] = useState('');
    const [observacao, setObservacao] = useState('');
    const [tipoSolicitacao, setTipoSolicitacao] = useState('');
    const [subtipo, setSubtipo] = useState('');
    const [email, setEmail] = useState('');
    const location = useLocation();
    const [mensagem, setMensagem] = useState('');
    //  const [ambientes, setAmb] = useState(teste);
    const [task, setTask] = useState([]);
    const navigate = useNavigate();
    const [filtro, setFiltro] = useState(99);
    const [ativarButton, setAtivarButton] = useState(undefined);
    const [dataIni, setDataini] = useState('');
    const [datafim, setDatafim] = useState('');
    const [notif, setNotif] = useState('9');
    const [renderNotif, setRenderNotif] = useState();
    const [quantNot, setQuantNot] = useState('0');
    const [userApp, setUserApp] = useState('');

    const [optionsEmpresa, setOptionsEmpresa] = useState([])
    const [optionsLocais, setOptionsLocais] = useState([])
    const [optionsLocaisFilter, setOptionsLocaisFilter] = useState([])

    const [optionsTipoSolicitacao, setOptionsTipoSolicitacao] = useState([])
    const [optionsSubTipo, setOptionsSubTipo] = useState([])
    const [optionsSubTipoFilter, setOptionsSubTipoFilter] = useState([])

    const [data, setData] = useState([]);
    const [tasks, setTasks] = useState([])
    const [selection, setSelection] = useState({ 20: "", 30: "", 40: "", 50: "", 99: "" });
    const [optionsLocal, setOptionsLocal] = useState([])
    const [optionsLoc, setOptionsLoc] = useState([])

    useEffect(() => {


       
    }, []);


    return (

        <DashboardBackground>

          

        </DashboardBackground>

    )
}

export default Relatorio;