import styled from 'styled-components';


export const CardContainer = styled.div<{
    width: string;
    height: string;

}>`
    width: ${(props: any) => props.width};
    height: 400px;

    background:#FFFFFF;


 
    
    border-radius: 20px;
    
    margin: 25px;
    //padding: 25px;
    

    display:flex;
    align-items: center;
    flex-direction: column;

`