import { Wrapper, InputContainer, ButtonContainer, AlertaT } from './styles';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import nowT from '../../assets/images/nowT.bmp';
import Card from '../../components/CardLogin';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../Services/api';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import secureLocalStorage from "react-secure-storage";

const SignIn = () => {

    const navigate = useNavigate();

    let { ambiente } = useParams();

    const [amb, setAmb] = useState('');
    const [ambdisabled, setAmbdisabled] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [senha, setsenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [openModal, setOpenModal] = useState(false)
    var hostname = window.location.hostname;


    const something = (event: any) => {
        if (event.keyCode === 13) {
            handleToSingIn();
        }
    };

    useEffect(() => {

    
    
        setAmbdisabled(true)
        //hostname.split('.')[0]
        setAmb('BEACHCLASS')


        if (sessionStorage.getItem("userToken") !== null) {
            navigate('/dashboard');
        }
    }, [navigate]);


    function fecharModal() {
        setOpenModal(false)
    }

    async function handleToSingIn() {
        let ret: AxiosResponse;

        if (amb === "" || usuario === "" || senha === "") {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Todos os campos devem ser preenchidos.`,
                confirmButtonColor: "#222d32",
            })


        } else {


            axios.get('https://apicentral.adlim.com.br:3334/ambientes/validar', {
                params: {
                    ambiente: amb
                }
            })
                .then(async function (response) {


                    setSucesso('S');

                    let endPoint = response.data.data[0].amb_endpointweb

                    axios.get(endPoint + '/user/login', {
                        params: {
                            company: amb,
                            login: usuario,
                            senha: senha,
                        }
                    }).then(async function (response) {

                            secureLocalStorage.setItem('ep', endPoint);
                            sessionStorage.setItem("userToken", response.data.userToken);
                            sessionStorage.setItem("username", response.data.data[0].age_name);
                            sessionStorage.setItem("id", response.data.data[0].age_id);
                            sessionStorage.setItem("phone", response.data.data[0].age_mobilephone);
                            sessionStorage.setItem("amb", amb);

                          
                            navigate('/dashboard')

                        }).catch(async function (error) {

                            setSucesso('N');
                          

                        });

                })
                .catch(async function (error) {
                    setSucesso('N');
                   

                });
        }

    }


    return (
        <>
            <Wrapper>

                <Card width="403px" height="auto">
                    <img src={nowT} width={300} height={100} alt="now" />

                    <InputContainer>
                        <Input placeholder="Digite aqui seu ambiente" onChange={(e) => setAmb(e.target.value)} value={amb} disabled={ambdisabled} />
                        <Input placeholder="Digite aqui seu usuário" onChange={(e) => setUsuario(e.target.value)} />
                        <Input placeholder="Digite aqui sua senha" type="password" onKeyDown={(e) => something(e)} onChange={(e) => setsenha(e.target.value)} />
                    </InputContainer>

                    {sucesso === 'N' ? <AlertaT><p>Usuário ou senha inválida.</p></AlertaT> : null}
                    <ButtonContainer>
                        <Button type="button" onClick={handleToSingIn}>ENTRAR</Button>

                        {/* <p><Link to="/signup">Esqueceu sua senha?</Link></p> */}
                    </ButtonContainer>

                </Card>


            </Wrapper>

        </>
    )
}

export default SignIn;
