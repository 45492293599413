import styled from 'styled-components';


export const ButtonContainer = styled.button`
    width: 80%;
    height: 46px;
    color: #FFFFFF;
    background:#52b788;
    border: 1px solid #52b788;
    border-radius: 10px;
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    &:hover {
        filter: opacity(0.8)
    }

    &:disabled {
        filter: opacity(0.4)
    }

    
`