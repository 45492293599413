import styled from "styled-components";


interface ITagProps {
  color: string;
}

interface IButtonProps {
  color: string;
}

export const IButton = styled.div<IButtonProps>`
    background-color: ${props => props.color};
    width: 60px;
    height: 10px;
`;

export const Container = styled.li`
background-color: ${props => props.theme.colors.tertiary};

justify-content: space-between;
list-style: none;
border-radius:  5px;
margin: 10px ;
padding: 10px 20px 10px;
display: flex;

align-items: center;
cursor: pointer;
transition: all .3s;

position: relative;

&:hover{
  transform: translateX(4px);
}

> div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}
.icont{
  color: #4CAF50;
  margin-top: 3px;
}
`;

export const Tag = styled.div<ITagProps>`
    width: 0px;
    height: 60%;
    background-color: ${props => props.color};
    position: absolute;
    left: 0;
`;

export const Title = styled.div`
 text-align: left;
 font-weight: bold;
 span{
  font-size: 13px; 
 }
`
export const ContResumo = styled.div`
margin: 0 0 0 10px;
padding-bottom: 10px;

  .text{
    margin-top: 1px;
    
  }
  span{
    text-transform: uppercase;
    font-size: 13px; 
  }
  b{
    font-size: 13px; 
  }
  .text .textid {
    font-size: 16px;
    color: #52b788;
    cursor: pointer;
  }
  .text .textid:hover{
    font-size: 16px;
    color: red;
  }
  .text3{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .text3 b{
    font-size: 20px; 

  }
  .text-flex{
    margin-bottom: 10px;
  }

`
export const TitleClassif = styled.div`
  margin-top: 25px;
  text-align: center;

  h2{
    font-size: 13px; 
    margin-bottom: 5px;
    text-transform: uppercase;
  }
 
  input{
    margin: 5px;
    font-weight: bold;
  }
`

export const Tmoda = styled.div`
margin: 10px;
  .text{
    margin-top: 7px
  }
`

export const ButtonContainer = styled.div`
   width: 80%;
   margin-top: 20px;
   align-items: center;
`